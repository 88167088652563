import {ReactNode} from "react";
import Box from "@amzn/awsui-components-react/polaris/box";
import {RadioGroupProps} from "@amzn/awsui-components-react/polaris/radio-group";

export function getMatchesCountText(count: number) {
    return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
    title: string;
    subtitle: string;
    action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] = [
    { value: 'table', label: 'Table' },
    { value: 'cards', label: 'Cards' }
];