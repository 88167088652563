import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { Root, stageTime } from '../../../rss-visualizer-api';

export const rootIsTestToString = (item: Root) => {return item.isTestRoot ? "Test" : "Prod"};

export const predecessorRootNameToString = (item: Root) => {return (item.predecessorRootName === undefined)
    ? "--" : item.predecessorRootName};

export const rootCreateTimeToString = (item: Root) => {return stageToString(item.createTime)}
export const rootActiveTimeToString = (item: Root) => {return stageToString(item.activeTime)}
export const rootRetireTimeToString = (item: Root) => {return stageToString(item.retireTime)}
export const rootExpireTimeToString = (item: Root) => {return stageToString(item.expireTime)}
export const rootDeadTimeToString = (item: Root) => {return stageToString(item.deadTime)}

const stageToString = (stage: stageTime | undefined) => {return (stage === undefined) ?
    "--" : new Date(stage.seconds * 1000).toString()};

const rootTypeSortingComparator = (a: Root, b: Root) => {return (a.isTestRoot ? 0 : 1) - (b.isTestRoot ? 0 : 1)};
const stageTimeSortingComparator = (a: stageTime | undefined, b: stageTime | undefined) => ((a === undefined) ? 0 : a.seconds) - ((b === undefined) ? 0 : b.seconds);

export const createTimeComparator = (a: Root, b: Root) => (stageTimeSortingComparator(a.createTime, b.createTime))
const activeTimeComparator = (a: Root, b: Root) => (stageTimeSortingComparator(a.activeTime, b.activeTime))
const retireTimeComparator = (a: Root, b: Root) => (stageTimeSortingComparator(a.retireTime, b.retireTime))
const expireTimeComparator = (a: Root, b: Root) => (stageTimeSortingComparator(a.expireTime, b.expireTime))
const deadTimeComparator = (a: Root, b: Root) => (stageTimeSortingComparator(a.deadTime, b.deadTime))

export const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<Root>[] = [
  {
    id: 'rootName',
    header: 'Root Name',
    cell: item => item.rootName,
    width: 350,
  },
  {
    id: 'predecessorRootName',
    header: 'Predecessor Root Name',
    cell: item => predecessorRootNameToString(item),
    width: 350,
  },
  {
    id: 's3Bucket',
    cell: item => item.s3Bucket,
    header: 'S3 Bucket',
    width: 250,
    sortingField: 's3Bucket'
  },
  {
    id: 'rootState',
    header: 'Root State',
    cell: item => item.rootState,
    width: 150,
    sortingField: 'rootState'
  },
  {
    id: 'rootType',
    header: 'Root Type',
    cell: item => rootIsTestToString(item),
    width: 150,
    sortingComparator: rootTypeSortingComparator
  },
  {
    id: 'createTime',
    header: 'Create Time',
    cell: item => rootCreateTimeToString(item),
    sortingField: 'createTime',
    width: 300,
    sortingComparator: createTimeComparator
  },
  {
    id: 'activeTime',
    header: 'Active Time',
    cell: item => rootActiveTimeToString(item),
    sortingField: 'activeTime',
    width: 300,
    sortingComparator: activeTimeComparator
  },
  {
    id: 'retireTime',
    header: 'Retire Time',
    cell: item => rootRetireTimeToString(item),
    sortingField: 'retireTime',
    width: 300,
    sortingComparator: retireTimeComparator
  },
  {
    id: 'expireTime',
    header: 'Expire Time',
    cell: item => rootExpireTimeToString(item),
    sortingField: 'expireTime',
    width: 300,
    sortingComparator: expireTimeComparator
  },
  {
    id: 'deadTime',
    header: 'Dead Time',
    cell: item => rootDeadTimeToString(item),
    sortingField: 'deadTime',
    width: 300,
    sortingComparator: deadTimeComparator
  }
];