import * as React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Regions from "../Regions";
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import {useEffect, useState} from "react";
import {AxiosRequestConfig} from "axios";
import {existenceValidation, Migration} from "../../../rss-visualizer-api";
import RssVisualizerFactory from "../../../rss-visualizer-api/RssVisualizerApiFactory";
import {useHistory, useLocation} from "react-router-dom";

export default function ValidationTable() {
    let query = useQuery();
    const querySuperManifestUriParam = query.get("superManifestUri");
    const queryRegionParam = query.get("region");

    const [regionPathSuffix, setRegionPathSuffix] = useState(queryRegionParam ? queryRegionParam : "PDX/Cell-0");
    const [superManifestUri, setSuperManifestUri] = useState(querySuperManifestUriParam ? querySuperManifestUriParam : "");
    const [loading, setLoading] = useState(false);
    const [validationOutput, setValidationOutput] = useState<existenceValidation>();
    const [errorMessage, setErrorMessage] = useState("");
    const history = useHistory();

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    useEffect(()=>{
        if (querySuperManifestUriParam && queryRegionParam) {
            submit();
        }
    }, [])

    const RssVisualizerApi = RssVisualizerFactory("/" + regionPathSuffix);

    const onChange = (attribute, value) => {
        setSuperManifestUri(value);
    };

    function submit() {
        history.push({
            pathname: '/validation',
            search: `?region=${regionPathSuffix}&superManifestUri=${superManifestUri}`
        })

        setValidationOutput(undefined);
        setLoading(true);
        const request : AxiosRequestConfig = {data: {"superManifestUri": superManifestUri}};
        (async () => {
            const result = (await RssVisualizerApi.superManifestExistenceValidation(request)).data;
            setErrorMessage("");
            setValidationOutput(result);
            setLoading(false)
        })().catch((err) => {
            setErrorMessage(err.response.data.message)
            setLoading(false)
        });
    }

    function table() {
        if (loading) {
            return (
                <h2>Loading...</h2>
            )
        } else if (errorMessage) {
            return (
                <>
                    <h2>Error</h2>
                    <p>{errorMessage}</p>
                </>
            )
        } else if (validationOutput) {
            return (
                <>
                    <h2>Output</h2>
                    <p>
                        <b>SuperManifest Uri:</b> {validationOutput?.superManifestUri}
                        <br/>
                        <b>Does SuperManifest Exist:</b> {validationOutput?.superManifestExists ? "True" : "False"}
                        <br/>
                        <b>Current Root Name:</b> {validationOutput?.currentRootName ? validationOutput?.currentRootName : "N/A"}
                    </p>
                </>
            )
        } else {
            return (
                <>
                    <p>Enter a SuperManifest Uri above.</p>
                </>
            )
        }
    }

    return (
        <>
            <Container
                header={
                    <Header
                        variant="h2"
                        actions={<ButtonDropdown
                            items={Regions()}
                            onItemClick={event => {
                                if (event.detail.id !== regionPathSuffix) {
                                    setRegionPathSuffix(event.detail.id);
                                }
                            }}
                        >
                            {regionPathSuffix}
                        </ButtonDropdown>}
                    >
                        Select a region and enter a SuperManifest Uri to validate
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="s">
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xxxs">
                                <Button variant="primary" onClick={submit}>Submit</Button>
                            </SpaceBetween>
                        }
                    >

                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="superManifestUri" stretch={true}>
                                <Input
                                    value={superManifestUri ? superManifestUri : ""}
                                    ariaRequired={true}
                                    placeholder="superManifestUri"
                                    onChange={({ detail: { value } }) => onChange('id', value)}
                                />
                            </FormField>
                        </SpaceBetween>
                    </Form>
                </SpaceBetween>
            </Container>

            <br/>

            <Container>
                {table()}
            </Container>
        </>
    );
}