import { useState, useEffect } from 'react';
import {useHistory, useLocation} from "react-router-dom";
import Form from "@amzn/awsui-components-react/polaris/form";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from '@amzn/awsui-components-react/polaris/input';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Button from '@amzn/awsui-components-react/polaris/button';
import Container from '@amzn/awsui-components-react/polaris/container';
import RssVisualizerFactory from "../../../rss-visualizer-api/RssVisualizerApiFactory";
import Regions, {RegionCodeToRegion} from "../Regions";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import {AxiosRequestConfig} from "axios";
import {
    COLUMN_DEFINITIONS_CREATOR,
    migrationCompleteTimeToString,
    migrationCreateTimeToString, migrationQueueTimestampToString
} from "./migration-table-config";
import {
    EmptyState,
    getMatchesCountText,
    paginationLabels
} from "../table-config";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Table from "@amzn/awsui-components-react/polaris/table";
import {Migration, Root} from "../../../rss-visualizer-api";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {createTimeComparator} from "./migration-table-config"
import * as React from "react";

export default function MigrationsTable() {
    let query = useQuery();
    const queryFunctionArnParam = query.get("functionArn");
    const queryRegionParam = query.get("region");

    const [allItems, setAllItems] = useState<Migration[]>();
    const [noMigrationsTitle, setNoMigrationsTitle] = useState("No migrations")
    const [noMigrationsMessage, setNoMigrationsMessage] = useState("Enter a functionArn above")
    const [loading, setLoading] = useState(false);
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);
    const [regionPathSuffix, setRegionPathSuffix] = useState(queryRegionParam ? queryRegionParam : "PDX/Cell-0");
    const [submittedRegionPathSuffix, setSubmittedRegionPathSuffix] = useState(queryRegionParam ? queryRegionParam : "PDX/Cell-0");
    const [functionArn, setFunctionArn] = useState(queryFunctionArnParam ? queryFunctionArnParam : "");
    const [submittedFunctionArn, setSubmittedFunctionArn] = useState(queryFunctionArnParam ? queryFunctionArnParam : "");
    const [lastMigrationKey, setLastMigrationKey] = useState(null);
    const [paginationRunning, setPaginationRunning] = useState(false);
    const history = useHistory();

    const RssVisualizerApi = RssVisualizerFactory("/" + submittedRegionPathSuffix);

    const COLUMN_DEFINITIONS = COLUMN_DEFINITIONS_CREATOR(RegionCodeToRegion.get(regionPathSuffix.substring(0, regionPathSuffix.indexOf("/")))!);

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    useEffect(()=>{
        if (queryFunctionArnParam && queryRegionParam) {
            submit();
        }
    }, [])

    const onChange = (attribute, value) => {
        setFunctionArn(value);
    };

    function submit() {
        setPaginationRunning(false);
        setSubmittedFunctionArn(functionArn);
        setSubmittedRegionPathSuffix(regionPathSuffix);
        history.push({
            pathname: '/migrations',
            search: `?region=${regionPathSuffix}&functionArn=${functionArn}`
        })

        setAllItems(undefined);
        setLoading(true);
        setLastMigrationKey(null);
        const request : AxiosRequestConfig = {data: {"functionArn": functionArn, "exclusiveStartKey": null}};
        (async () => {
            const result = (await RssVisualizerApi.listMigrations(request)).data;
            setNoMigrationsTitle("No migrations")
            setNoMigrationsMessage("No migrations found")
            setAllItems(result["migrations"]);
            setLoading(false)
            setLastMigrationKey(result["lastEvaluatedKey"])
        })().catch((err) => {
            setNoMigrationsTitle("Error")
            setNoMigrationsMessage(err.response.data.message)
            setLoading(false)
        });
    }

    function loadMoreMigrations() {
        if (lastMigrationKey != null) {
            setPaginationRunning(true);
        }
    }

    useEffect(() => {
        if (paginationRunning) {
            const request: AxiosRequestConfig = {
                data: {
                    "functionArn": functionArn,
                    "exclusiveStartKey": lastMigrationKey
                }
            };
            (async () => {
                setPaginationRunning(true);
                const result = (await RssVisualizerApi.listMigrations(request)).data;
                if (paginationRunning) {
                    let currentItems;
                    if (allItems == undefined) {
                        currentItems = []
                    } else {
                        currentItems = allItems
                    }
                    currentItems.push.apply(currentItems, result["migrations"]);

                    setAllItems(currentItems);
                    setLastMigrationKey(result["lastEvaluatedKey"])
                    setPaginationRunning(false);
                    setLoading(false);
                }
            })().catch((err) => {
                if (paginationRunning) {
                    setNoMigrationsTitle("Error")
                    setNoMigrationsMessage(err.response.data.message)
                    setPaginationRunning(false);
                    setLoading(false);
                }
            });
        }
    }, [paginationRunning])

    const refreshButton = (
        <Button variant="primary" iconName="refresh" onClick={() => {submit()}}>
            Refresh
        </Button>
    );

    function tableActions() {
        if (lastMigrationKey == null) {
            return <></>
        } else {
            return(
                <Button
                    onClick={event => {
                        loadMoreMigrations()
                    }}
                    disabled={paginationRunning}
                >
                    Load More Migrations
                </Button>
            )
        }
    }

    const filteringFunction = (item: Migration, text: string, fields?: string[]) => {
        text = text.toLowerCase();
        if (item.migrationId.toLowerCase().includes(text)) {
            return true;
        } else if (item.superManifestUrl.toLowerCase().includes(text)) {
            return true;
        } else if (item.oldRootName.toLowerCase().includes(text)) {
            return true;
        } else if (item.newRootName.toLowerCase().includes(text)) {
            return true;
        } else if (item.superManifestSigner.toLowerCase().includes(text)) {
            return true;
        } else if (item.newSuperManifestUrl.toLowerCase().includes(text)) {
            return true;
        } else if (item.state.toLowerCase().includes(text)) {
            return true;
        } else if (migrationCreateTimeToString(item).toLowerCase().includes(text)) {
            return true;
        } else if (migrationCompleteTimeToString(item).toLowerCase().includes(text)) {
            return true;
        } else if (migrationQueueTimestampToString(item).toLowerCase().includes(text)) {
            return true;
        } else if (item.migrationExecution.executionArn.toLowerCase().includes(text)) {
            return true;
        }
        return false;
    }

    const {items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps} = useCollection(
        allItems || [],
        {
            filtering: {
                filteringFunction: filteringFunction,
                empty: (
                    <EmptyState
                        title={noMigrationsTitle}
                        subtitle={noMigrationsMessage}
                        action={refreshButton}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 50
            },
            sorting: {
                defaultState: {
                    isDescending: true,
                    sortingColumn: {
                        sortingField: "createTime",
                        sortingComparator: createTimeComparator
                    }
                }
            },
            selection: {}
        }
    );

    const {selectedItems} = collectionProps;
    return (
        <>
                    <Container header={
                        <Header
                            variant="h2"
                            actions={<ButtonDropdown
                                items={Regions()}
                                onItemClick={event => {
                                    if (event.detail.id !== regionPathSuffix) {
                                        setRegionPathSuffix(event.detail.id);
                                    }
                                }}
                            >
                                {regionPathSuffix}
                            </ButtonDropdown>}
                        >
                            Select a region and enter a functionArn to get its migrations
                        </Header>
                    }>


                        <SpaceBetween direction="vertical" size="s">
                            <Form
                                actions={
                                    <SpaceBetween direction="horizontal" size="xxxs">
                                        <Button variant="primary" onClick={submit}>Submit</Button>
                                    </SpaceBetween>
                                }
                            >

                                <SpaceBetween direction="vertical" size="l">
                                    <FormField label="functionArn" stretch={true}>
                                        <Input
                                            value={functionArn}
                                            ariaRequired={true}
                                            placeholder="functionArn"
                                            onChange={({ detail: { value } }) => onChange('id', value)}
                                        />
                                    </FormField>
                                </SpaceBetween>
                            </Form>
                        </SpaceBetween>
                    </Container>

                    <br/>

                    <Table
                        {...collectionProps}
                        loading={loading}
                        loadingText="Loading migrations"
                        resizableColumns
                        header={
                            <Header
                                counter={
                                    allItems &&
                                    (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                                }
                                actions={tableActions()}
                            >
                                Migrations
                            </Header>
                        }
                        columnDefinitions={COLUMN_DEFINITIONS}
                        items={items}
                        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
                        filter={
                            <TextFilter
                                {...filterProps}
                                countText={getMatchesCountText(filteredItemsCount!)}
                                filteringPlaceholder="Filter"
                                filteringAriaLabel="Filter migrations"
                            />
                        }
                    />

                    <br/>

                    <Container>
                        <b>Note</b> that the ExecutionArn links point to step function executions in the Root Storage Service accounts.
                    </Container>
        </>
    );
}