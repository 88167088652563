import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import MigrationPageContent from './MigrationPageContent';
import '../table-styles.scss';

export default function MigrationsPage() {
    return (
        <AppLayout
            content={<MigrationPageContent />}
            contentType="default"
            navigationHide={true}
            toolsHide={true}
            disableContentPaddings={true}
        />
    );
}
