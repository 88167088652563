import {Route, Switch} from 'react-router-dom';
import * as React from "react";
import RootsPage from './roots';
import MigrationsPage from './migrations';
import ValidationPage from "./validation";
import HomePage from "./home";
import CommonNavbar from "./CommonNavbar";

export default function App() {
    return (
        <div>
            <CommonNavbar/>

            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/roots" component={RootsPage}/>
                <Route exact path="/migrations" component={MigrationsPage}/>
                <Route exact path="/validation" component={ValidationPage}/>
            </Switch>
        </div>
    );
}
