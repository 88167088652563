import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Header from '@amzn/awsui-components-react/polaris/header';
import Cards from "@amzn/awsui-components-react/polaris/cards";
import {useHistory} from "react-router-dom";
import {getAppSetting} from "../../config/AppSettings";
import {Link} from "@amzn/awsui-components-react";

// The content in the main content area of the App layout
export default function HomePageContent() {
    const history = useHistory();
    const websiteStage = getAppSetting('stage');

    return (
        <>
            <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
                <Grid
                    gridDefinition={[
                        { colspan: { xl: 10, l: 8, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } }
                    ]}
                >
                    <SpaceBetween size="l">
                        <div>
                            <h1>{websiteStage} Root Storage Service Visualizer Home</h1>
                            <p>The Root Storage Service visualizer is a self-service tool for clients to easily find roots and migration metadata.
                                Instead of having to ask the Root Storage Service team directly to find metadata, the Root Storage Service
                                visualizer makes it faster for clients to collect metadata.</p>

                            <p>Our team's CTI is AWS / Lambda / Garbage Collection. If you run into any problems, feel free
                                to cut us a ticket using this <Link
                                    external
                                    externalIconAriaLabel="Opens in a new tab"
                                    href="https://t.corp.amazon.com/create/options?category=AWS&type=Lambda&item=GarbageCollection&group=AWS%20Lambda%20Snapshot%20CP"
                                >
                                    link.
                                </Link>
                            </p>
                        </div>


                        <Cards
                            ariaLabels={{
                                itemSelectionLabel: (e, t) => `select ${t.name}`,
                                selectionGroupLabel: "Item selection"
                            }}
                            cardDefinition={{
                                header: item => (
                                    <Link fontSize="heading-m" onFollow={() => {
                                        history.push({
                                            pathname: `/${item.path}`,
                                        })
                                    }}>{item.name}</Link>
                                ),
                                sections: [
                                    {
                                        id: "description",
                                        header: "Description",
                                        content: item => item.description
                                    },
                                ]
                            }}
                            cardsPerRow={[
                                { cards: 1 },
                                { minWidth: 500, cards: 3 }
                            ]}
                            items={[
                                {
                                    name: "Get Roots",
                                    description: "Given a region, will return all roots in that region.",
                                    path: "roots"
                                },
                                {
                                    name: "List Migrations",
                                    description: "Given a FunctionArn and a region, will return all migrations related to the functionArn.",
                                    path: "migrations"
                                },
                                {
                                    name: "Validate SuperManifest Existence",
                                    description: "Given a SuperManifest Uri and a region, will return whether the SuperManifest exists in RSS.",
                                    path: "validation"
                                }
                            ]}
                            header={<Header>Pages</Header>}
                        />
                    </SpaceBetween>
                </Grid>
            </Box>
        </>
    );
}
