import {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {useCollection} from '@amzn/awsui-collection-hooks';
import Container from '@amzn/awsui-components-react/polaris/container';
import Button from '@amzn/awsui-components-react/polaris/button';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import TextFilter from '@amzn/awsui-components-react/polaris/text-filter';
import RssVisualizerFactory from '../../../rss-visualizer-api/RssVisualizerApiFactory';
import {Root} from '../../../rss-visualizer-api';
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import {
    COLUMN_DEFINITIONS,
    rootIsTestToString,
    rootCreateTimeToString,
    rootDeadTimeToString,
    rootExpireTimeToString,
    rootActiveTimeToString,
    rootRetireTimeToString,
    predecessorRootNameToString,
    createTimeComparator
} from './roots-table-config'
import {
    getMatchesCountText, EmptyState, paginationLabels
} from "../table-config";
import Regions from "../Regions";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import * as React from "react";

export default function RootsTable() {
    let query = useQuery();
    const queryRegionParam = query.get("region");

    const [allItems, setAllItems] = useState<Root[]>();
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);
    const [maxId, setMaxId] = useState(0);
    const [regionPathSuffix, setRegionPathSuffix] = useState(queryRegionParam ? queryRegionParam : "PDX/Cell-0");
    const history = useHistory();

    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    useEffect(()=>{
        if (queryRegionParam) {
            (async () => {
                setAllItems((await RssVisualizerApi.getRoots()).data["roots"]);
            })();
        }
    }, [])

    const refreshButton = (
        <Button variant="primary" iconName="refresh" onClick={() => {setRefreshTableFlag(!refreshTableFlag); setAllItems(undefined)}}>
            Refresh
        </Button>
    );
    const RssVisualizerApi = RssVisualizerFactory("/" + regionPathSuffix);
    useEffect(() => {
        history.push({
            pathname: '/roots',
            search: `?region=${regionPathSuffix}`
        });

        (async () => {
            setAllItems((await RssVisualizerApi.getRoots()).data["roots"]);
        })();
    }, [refreshTableFlag, regionPathSuffix]);

    useEffect(() => {
        setMaxId(1 + (items.length > 0 ? Math.max(...items.map(i => i.createTime.seconds)) : 0));
    }, [allItems]);

    const filteringFunction = (item: Root, text: string, fields?: string[]) => {
        text = text.toLowerCase();
        if (item.rootName.toLowerCase().includes(text)) {
            return true;
        } else if (item.s3Bucket.toLowerCase().includes(text)) {
            return true;
        } else if (item.rootState.toLowerCase().includes(text)) {
            return true;
        } else if (rootIsTestToString(item).toLowerCase().includes(text)) {
            return true;
        } else if (predecessorRootNameToString(item).toLowerCase().includes(text)) {
            return true;
        } else if (rootCreateTimeToString(item).toLowerCase().includes(text)) {
            return true;
        } else if (rootActiveTimeToString(item).toLowerCase().includes(text)) {
            return true;
        } else if (rootRetireTimeToString(item).toLowerCase().includes(text)) {
            return true;
        } else if (rootExpireTimeToString(item).toLowerCase().includes(text)) {
            return true;
        } else if (rootDeadTimeToString(item).toLowerCase().includes(text)) {
            return true;
        }
        return false;
    }

    const {items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps} = useCollection(
        allItems || [],
        {
            filtering: {
                filteringFunction: filteringFunction,
                empty: (
                    <EmptyState
                        title="No roots"
                        subtitle="No roots to display."
                        action={refreshButton}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: 50
            },
            sorting: {
                defaultState: {
                    isDescending: true,
                    sortingColumn: {
                        sortingField: "createTime",
                        sortingComparator: createTimeComparator
                    }
                }
            },
            selection: {}
        }
    );

    const {selectedItems} = collectionProps;
    return (
            <>
                <Container header={
                    <>
                        <Header
                            variant="h2"
                            actions={<ButtonDropdown
                                items={Regions()}
                                onItemClick={event => {
                                    if (event.detail.id !== regionPathSuffix) {
                                        setRegionPathSuffix(event.detail.id);
                                        setAllItems(undefined);
                                    }
                                }}
                            >
                                {regionPathSuffix}
                            </ButtonDropdown>}
                        >
                            Select a region to all roots in the RSS of that region
                        </Header>
                    </>
                }>
                    <SpaceBetween direction="vertical" size="s">
                        <Table
                            {...collectionProps}
                            loading={!allItems}
                            loadingText="Loading roots"
                            resizableColumns
                            header={
                                <Header
                                    counter={
                                        allItems &&
                                        (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                                    }
                                >
                                    Roots
                                </Header>
                            }
                            columnDefinitions={COLUMN_DEFINITIONS}
                            items={items}
                            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels}/>}
                            filter={
                                <TextFilter
                                    {...filterProps}
                                    countText={getMatchesCountText(filteredItemsCount!)}
                                    filteringPlaceholder="Filter"
                                    filteringAriaLabel="Filter roots"
                                />
                            }
                        />
                    </SpaceBetween>
                </Container>
            </>
    );
}
