import * as React from "react";
import TopNavigation from "@amzn/awsui-components-react/polaris/top-navigation";
import { useDate } from "./time/Time";
import { getAppSetting } from '../config/AppSettings';

export default () => {
    const websiteStage = getAppSetting('stage');

    const {
        date,
        time,
        time_zone,
    } = useDate();

    return (
        <TopNavigation
            identity={{
                href: "#",
                title: `${websiteStage} Root Storage Service Visualizer`,
            }}
            utilities={[
                {
                    type: "button",
                    text: `${date}, ${time} (${time_zone})`,
                    external: false,
                },
                {
                    type: "menu-dropdown",
                    text: "Pages",
                    items: [
                        {
                            id: "home",
                            text: "Home",
                            href: "/#",
                            external: false,
                        },
                        {
                            id: "get-roots",
                            text: "Get Roots",
                            href: "/#/roots",
                            external: false,
                        },
                        {
                            id: "list-migrations",
                            text: "List Migrations",
                            href: "/#/migrations",
                            external: false,
                        },
                        {
                            id: "supermanifest-validation",
                            text: "SuperManifest Validation",
                            href: "/#/validation",
                            external: false,
                        }
                    ]
                }
            ]}
            i18nStrings={{
                searchIconAriaLabel: "Search",
                searchDismissIconAriaLabel: "Close search",
                overflowMenuTriggerText: "More",
                overflowMenuTitleText: "All",
                overflowMenuBackIconAriaLabel: "Back",
                overflowMenuDismissIconAriaLabel: "Close menu"
            }}
        />
    );
}