import {getAppSetting} from "../../config/AppSettings";

export default function Regions() {
    const websiteStage: string = getAppSetting('stage');

    if (websiteStage.toLowerCase() === "beta") {
        return ([
            {
                text: "PDX",
                items: [
                    { text: "PDX/Cell-0", id: "PDX/Cell-0" },
                    { text: "PDX/Cell-1", id: "PDX/Cell-1" }
                ]
            }
        ])
    } else {
        return ([])
    }
}

export const RegionCodeToRegion = new Map<string, string>([
    ["PDX", "us-west-2"],
]);