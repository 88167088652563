/* tslint:disable */
/* eslint-disable */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     *
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     *
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
}
/**
 *
 * @export
 * @interface Root
 */
export interface Root {
    /**
     *
     * @type {string}
     * @memberof Root
     */
    'rootName': string;
    /**
     *
     * @type {string}
     * @memberof Root
     */
    's3Bucket': string;
    /**
     *
     * @type {string}
     * @memberof Root
     */
    'rootState': "ACTIVE" | "RETIRED" | "EXPIRED" | "DEAD";
    /**
     *
     * @type {boolean}
     * @memberof Root
     */
    'isTestRoot': boolean;
    /**
     *
     * @type {string}
     * @memberof Root
     */
    'predecessorRootName'?: string;
    /**
     *
     * @type {stageTime}
     * @memberof Root
     */
    'createTime': stageTime;
    /**
     *
     * @type {stageTime}
     * @memberof Root
     */
    'activeTime'?: stageTime;
    /**
     *
     * @type {stageTime}
     * @memberof Root
     */
    'retireTime'?: stageTime;
    /**
     *
     * @type {stageTime}
     * @memberof Root
     *
     */
    'expireTime'?: stageTime;
    /**
     *
     * @type {stageTime}
     * @memberof Root
     */
    'deadTime'?: stageTime;
    /**
     *
     * @type {number}
     * @memberof Root
     */
    'version'?: number;
}

export interface stageTime {
    /**
     *
     * @type {number}
     * @memberof stageTime
     */
    'seconds': number;
    /**
     *
     * @type {number}
     * @memberof stageTime
     */
    'nanos': number;
}

/**
 *
 * @export
 * @interface Migration
 */
export interface Migration {
    /**
     *
     * @type {string}
     * @memberof Migration
     */
    'migrationId': string;
    /**
     *
     * @type {string}
     * @memberof Migration
     */
    'superManifestUrl': string;
    /**
     *
     * @type {string}
     * @memberof Migration
     */
    'oldRootName': string;
    /**
     *
     * @type {string}
     * @memberof Migration
     */
    'newRootName': string;
    /**
     *
     * @type {string}
     * @memberof Migration
     */
    'superManifestSigner': string;
    /**
     *
     *
     * @type {string}
     * @memberof Migration
     */
    'newSuperManifestUrl': string;
    /**
     *
     * @type {string}
     * @memberof Migration
     */
    'state': string;
    /**
     *
     * @type {number}
     * @memberof Migration
     */
    'createTime': stageTime;
    /**
     *
     * @type {number}
     * @memberof Migration
     */
    'migrationCompleteTimestamp': stageTime;
    /**
     *
     * @type {number}
     * @memberof Migration
     */
    'queueTimestamp': stageTime;
    /**
     *
     * @type {migrationExecution}
     * @memberof Migration
     */
    'migrationExecution': migrationExecution;
    /**
     *
     * @type {number}
     * @memberof Root
     */
    'version'?: number;
}

export interface migrationExecution {
    /**
     *
     * @type {string}
     * @memberof migrationExecution
     */
    'executionArn': string;
    /**
     *
     * @type {stageTime}
     * @memberof migrationExecution
     */
    'startTime': stageTime;
    /**
     *
     * @type {number}
     * @memberof migrationExecution
     */
    'attempt': number;
}

export interface existenceValidation {
    /**
     *
     * @type {string}
     * @memberof migrationExecution
     */
    'superManifestUri': string;
    /**
     *
     * @type {boolean}
     * @memberof migrationExecution
     */
    'superManifestExists': boolean;
    /**
     *
     * @type {string}
     * @memberof migrationExecution
     */
    'currentRootName'?: string;
}

/**
 * RssVisualizerApi - axios parameter creator
 * @export
 */
export const RssVisualizerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary List all roots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoots: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/roots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
         *
         * @summary List all migrations for a functionArn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMigrations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/migrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
         *
         * @summary Returns whether a superManifest exists in S3 or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        superManifestExistenceValidation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/validate-supermanifest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RssVisualizerApi - functional programming interface
 * @export
 */
export const RssVisualizerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RssVisualizerApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary List all roots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoots(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Root>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoots(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
         *
         * @summary List all migrations for a functionArn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMigrations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Migration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMigrations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
         *
         * @summary Returns whether a superManifest exists in S3 or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async superManifestExistenceValidation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<existenceValidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.superManifestExistenceValidation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        }
    }
};

/**
 * RSSApiFactory - factory interface
 * @export
 */
export const RSSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RssVisualizerApiFp(configuration)
    return {
        /**
         *
         * @summary List all roots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoots(options?: any): AxiosPromise<Array<Root>> {
            return localVarFp.getRoots(options).then((request) => request(axios, basePath));
        },

        /**
         *
         * @summary List all migrations for a functionArn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMigrations(options?: any): AxiosPromise<Array<Migration>> {
            return localVarFp.listMigrations(options).then((request) => request(axios, basePath));
        },

        /**
         *
         * @summary Returns whether a superManifest exists in S3 or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        superManifestExistenceValidation(options?: any): AxiosPromise<existenceValidation> {
            return localVarFp.superManifestExistenceValidation(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RssVisualizerApi - object-oriented interface
 * @export
 * @class RssVisualizerApi
 * @extends {BaseAPI}
 */
export class RssVisualizerApi extends BaseAPI {
    /**
     *
     * @summary List all roots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RssVisualizerApi
     */
    public getRoots(options?: AxiosRequestConfig) {
        return RssVisualizerApiFp(this.configuration).getRoots(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary List all migrations for a functionArn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RssVisualizerApi
     */
    public listMigrations(options?: AxiosRequestConfig) {
        return RssVisualizerApiFp(this.configuration).listMigrations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Returns whether a superManifest exists in S3 or not
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RssVisualizerApi
     */
    public superManifestExistenceValidation(options?: AxiosRequestConfig) {
        return RssVisualizerApiFp(this.configuration).superManifestExistenceValidation(options).then((request) => request(this.axios, this.basePath));
    }
}


