import {getAppSetting} from "../../config/AppSettings";

export default function GetRSSAccount() {
    const websiteStage: string = getAppSetting('stage');

    if (websiteStage.toLowerCase() === "beta") {
        return "910395903292"
    } else {
        return ""
    }
}