import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import RootsPageContent from './RootsPageContent';
import '../table-styles.scss';

export default function RootsPage() {
  return (
    <AppLayout
      content={<RootsPageContent />}
      contentType="default"
      navigationHide={true}
      toolsHide={true}
      disableContentPaddings={true}
    />
  );
}
