import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { Migration, stageTime } from '../../../rss-visualizer-api';
import {Link} from "@amzn/awsui-components-react";
import GetRSSAccount from "../IsengardAccounts";

export const migrationCreateTimeToString = (item: Migration) => {return stageToString(item.createTime)}
export const migrationCompleteTimeToString = (item: Migration) => {return stageToString(item.migrationCompleteTimestamp)}
export const migrationQueueTimestampToString = (item: Migration) => {return stageToString(item.queueTimestamp)}

const stageToString = (stage: stageTime | undefined) => {return (stage === undefined) ?
    "--" : new Date(stage.seconds * 1000).toString()};

const stageTimeSortingComparator = (a: stageTime | undefined, b: stageTime | undefined) => ((a === undefined) ? 0 : a.seconds) - ((b === undefined) ? 0 : b.seconds);

export const createTimeComparator = (a: Migration, b: Migration) => (stageTimeSortingComparator(a.createTime, b.createTime))
const migrationCompleteTimeComparator = (a: Migration, b: Migration) => (stageTimeSortingComparator(a.migrationCompleteTimestamp, b.migrationCompleteTimestamp))
const queueTimestampComparator = (a: Migration, b: Migration) => (stageTimeSortingComparator(a.queueTimestamp, b.queueTimestamp))

export function COLUMN_DEFINITIONS_CREATOR(region: string): TableProps.ColumnDefinition<Migration>[] {return [
    {
        id: 'migrationId',
        header: 'Migration ID',
        cell: item => item.migrationId,
        width: 250
    },
    {
        id: 'superManifestUrl',
        header: 'SuperManifest Url',
        cell: item => item.superManifestUrl,
        width: 250
    },
    {
        id: 'oldRootName',
        header: 'Old Root Name',
        cell: item => item.oldRootName,
        width: 350
    },
    {
        id: 'newRootName',
        header: 'New Root Name',
        cell: item => item.newRootName,
        width: 350
    },
    {
        id: 'newSuperManifestUrl',
        cell: item => item.newSuperManifestUrl,
        header: 'New SuperManifest Url',
        width: 250
    },
    {
        id: 'superManifestSigner',
        header: 'SuperManifest Signer',
        cell: item => item.superManifestSigner,
        width: 225,
        sortingField: 'superManifestSigner'
    },
    {
        id: 'state',
        header: 'Migration State',
        cell: item => item.state,
        width: 175,
        sortingField: 'state'
    },
    {
        id: 'createTime',
        header: 'Create Time',
        cell: item => migrationCreateTimeToString(item),
        sortingField: 'createTime',
        width: 300,
        sortingComparator: createTimeComparator
    },
    {
        id: 'migrationCompleteTimestamp',
        header: 'Migration Complete Time',
        cell: item => migrationCompleteTimeToString(item),
        sortingField: 'migrationCompleteTimestamp',
        width: 300,
        sortingComparator: migrationCompleteTimeComparator
    },
    {
        id: 'queueTimestamp',
        header: 'Queue Timestamp',
        cell: item => migrationQueueTimestampToString(item),
        sortingField: 'queueTimestamp',
        width: 300,
        sortingComparator: queueTimestampComparator
    },
    {
        id: 'executionArn',
        header: 'Execution ARN',

        cell: item => <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={`https://isengard.amazon.com/federate?account=${GetRSSAccount()}&role=LambdaHumanRole&destination=states%2Fhome%3Fregion%3D${region}%23%2Fv2%2Fexecutions%2Fdetails%2F${item.migrationExecution.executionArn}`}
        >Link to ExecutionArn</Link>,
        width: 200
    },
]};
